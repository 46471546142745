import React from 'react';
import { Music2, Star, Gift, Sparkles, Wand2, Clock, Heart, Award } from 'lucide-react';

const features = [
  {
    icon: Wand2,
    title: 'AI-Powered Composition',
    description: 'Advanced technology helps create the perfect melody for your story.'
  },
  {
    icon: Heart,
    title: 'Personal Touch',
    description: 'Professional musicians add soul and emotion to every composition.'
  },
  {
    icon: Clock,
    title: 'Quick Turnaround',
    description: 'Receive your custom song within days, not weeks.'
  },
  {
    icon: Award,
    title: 'Studio Quality',
    description: 'Professional recording and mixing for broadcast-ready sound.'
  }
];

export function Features() {
  return (
    <div className="bg-gray-100 py-32">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-20">
          <h2 className="text-4xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-purple-600 to-indigo-600">
            The Future of Custom Music
          </h2>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            Combining artificial intelligence with human creativity to create songs that touch hearts
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-12">
          {features.map((feature, index) => (
            <div
              key={index}
              className="group bg-white p-8 rounded-2xl shadow-lg hover:shadow-2xl transition-all duration-300 hover:-translate-y-1"
            >
              <div className="bg-purple-100 p-3 rounded-xl w-fit mb-6 group-hover:bg-purple-600 transition-colors duration-300">
                <feature.icon className="h-8 w-8 text-purple-600 group-hover:text-white transition-colors duration-300" />
              </div>
              <h3 className="text-xl font-semibold mb-4 text-gray-900">
                {feature.title}
              </h3>
              <p className="text-gray-600">{feature.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}