import React from 'react';
import { CreditCard, Loader2 } from 'lucide-react';

interface PaymentButtonProps {
  isLoading: boolean;
  disabled?: boolean;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export function PaymentButton({ isLoading, disabled, onClick }: PaymentButtonProps) {
  return (
    <button
      type="button"
      onClick={onClick}
      disabled={isLoading || disabled}
      className="w-full bg-gradient-to-r from-purple-600 to-indigo-600 text-white py-3 px-6 rounded-lg font-semibold hover:from-purple-700 hover:to-indigo-700 transition-all duration-200 disabled:opacity-50 disabled:cursor-not-allowed flex items-center justify-center shadow-lg hover:shadow-xl"
    >
      {isLoading ? (
        <>
          <Loader2 className="animate-spin mr-2 h-5 w-5" />
          Processing Payment...
        </>
      ) : (
        <>
          <CreditCard className="h-5 w-5 mr-2" />
          Pay $49 and Send Message
        </>
      )}
    </button>
  );
}