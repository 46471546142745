import React from 'react';
import { Heart, Gift, Clock, Music2 } from 'lucide-react';
import { FeatureCard } from './feature-card/FeatureCard';

const features = [
  {
    icon: Heart,
    title: 'Personal Touch',
    description: 'Every song crafted with meticulous attention to your unique story',
    gradient: 'from-pink-500 to-rose-500'
  },
  {
    icon: Gift,
    title: 'Perfect Gift',
    description: 'Create unforgettable moments with personalized musical presents',
    gradient: 'from-purple-500 to-indigo-500'
  },
  {
    icon: Clock,
    title: 'Swift Delivery',
    description: 'Professional production with industry-leading turnaround time',
    gradient: 'from-blue-500 to-cyan-500'
  },
  {
    icon: Music2,
    title: 'Studio Quality',
    description: 'Professional recording and mastering for pristine sound',
    gradient: 'from-emerald-500 to-teal-500'
  }
];

export function HeroFeatures() {
  return (
    <div className="relative z-10 -mt-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          {features.map((feature, index) => (
            <FeatureCard key={index} {...feature} />
          ))}
        </div>
      </div>
    </div>
  );
}