import React, { useState, useEffect } from 'react';

export function CountdownTimer() {
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
  });

  useEffect(() => {
    const christmasDate = new Date(new Date().getFullYear(), 11, 25).getTime();

    const timer = setInterval(() => {
      const now = new Date().getTime();
      const distance = christmasDate - now;

      setTimeLeft({
        days: Math.floor(distance / (1000 * 60 * 60 * 24)),
        hours: Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
        minutes: Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)),
        seconds: Math.floor((distance % (1000 * 60)) / 1000)
      });
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return (
    <div className="bg-white/10 backdrop-blur-sm rounded-lg p-4 text-center">
      <h3 className="text-lg font-semibold mb-2">Holiday Order Deadline</h3>
      <div className="flex justify-center gap-4">
        {Object.entries(timeLeft).map(([unit, value]) => (
          <div key={unit} className="text-center">
            <div className="bg-white/20 rounded-lg p-2 min-w-[60px]">
              <span className="text-2xl font-bold">{value}</span>
            </div>
            <span className="text-sm mt-1 capitalize">{unit}</span>
          </div>
        ))}
      </div>
    </div>
  );
}