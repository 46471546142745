import { useState } from 'react';
import emailjs from '@emailjs/browser';
import { ContactFormData, FormStatus } from '../types/form';
import { EMAIL_CONFIG } from '../config/email';

export function useContactForm() {
  const [status, setStatus] = useState<FormStatus>({
    loading: false,
    error: null,
    success: false,
  });

  const sendEmail = async (data: ContactFormData) => {
    setStatus({ loading: true, error: null, success: false });

    try {
      await emailjs.send(
        EMAIL_CONFIG.SERVICE_ID,
        EMAIL_CONFIG.TEMPLATE_ID,
        {
          to_email: EMAIL_CONFIG.RECIPIENT_EMAIL,
          from_name: data.name,
          from_email: data.email,
          message: data.message,
        },
        EMAIL_CONFIG.PUBLIC_KEY
      );

      setStatus({ loading: false, error: null, success: true });
      return true;
    } catch (error) {
      setStatus({
        loading: false,
        error: 'Failed to send message. Please try again.',
        success: false,
      });
      return false;
    }
  };

  return { status, sendEmail };
}