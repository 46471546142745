import { useState, useEffect, useRef } from 'react';
import { Howl } from 'howler';

export function useAudioPlayer() {
  const [playing, setPlaying] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const soundRef = useRef<Howl | null>(null);

  useEffect(() => {
    return () => {
      if (soundRef.current) {
        soundRef.current.unload();
      }
    };
  }, []);

  const togglePlay = async (sampleId: string, audioUrl: string) => {
    try {
      if (playing === sampleId && soundRef.current) {
        soundRef.current.stop();
        setPlaying(null);
        return;
      }

      setLoading(true);
      setError(null);

      // Stop any currently playing audio
      if (soundRef.current) {
        soundRef.current.unload();
      }

      // Create new Howl instance
      const sound = new Howl({
        src: [audioUrl],
        html5: true,
        onload: () => {
          setLoading(false);
          sound.play();
          setPlaying(sampleId);
        },
        onloaderror: () => {
          setError('Failed to load audio. Please try again.');
          setLoading(false);
          setPlaying(null);
        },
        onplayerror: () => {
          setError('Failed to play audio. Please try again.');
          setLoading(false);
          setPlaying(null);
        },
        onend: () => {
          setPlaying(null);
        }
      });

      soundRef.current = sound;
    } catch (err) {
      setError('Failed to play audio. Please try again.');
      setLoading(false);
      setPlaying(null);
    }
  };

  return {
    playing,
    loading,
    error,
    togglePlay
  };
}