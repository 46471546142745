import React from 'react';
import { Header } from './components/Header';
import { Hero } from './components/Hero';
import { HeroFeatures } from './components/home/HeroFeatures';
import { UseCases } from './components/home/UseCases';
import { Features } from './components/Features';
import { Process } from './components/Process';
import { Pricing } from './components/Pricing';
import { Testimonials } from './components/Testimonials';
import { Contact } from './components/Contact';
import { Footer } from './components/Footer';
import { AudioSamples } from './components/AudioSamples';
import { SnowfallEffect } from './components/SnowfallEffect';
import { ChristmasLights } from './components/ChristmasLights';
import { CallToAction } from './components/home/CallToAction';

function App() {
  return (
    <div className="min-h-screen bg-gray-100">
      <SnowfallEffect />
      <ChristmasLights />
      <Header />
      <main className="pt-16">
        <Hero />
        <HeroFeatures />
        <UseCases />
        <section id="features">
          <Features />
        </section>
        <section id="samples">
          <AudioSamples />
        </section>
        <section id="process">
          <Process />
        </section>
        <CallToAction />
        <section id="pricing">
          <Pricing />
        </section>
        <section id="testimonials">
          <Testimonials />
        </section>
        <section id="contact">
          <Contact />
        </section>
      </main>
      <Footer />
    </div>
  );
}

export default App;