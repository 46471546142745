import { Sample } from '../types/audio';

export const samples: Sample[] = [
  {
    id: '1',
    title: 'Anniversary Love Song',
    description: 'A romantic ballad celebrating 25 years together',
    genre: 'Pop Ballad',
    audioUrl: 'https://res.cloudinary.com/dtksefjal/video/upload/v1733441396/evs84wof2h24wtzkkrxr.mp3'
  },
  {
    id: '2',
    title: 'Birthday Celebration',
    description: 'Upbeat pop song for a 30th birthday',
    genre: 'Pop Rock',
    audioUrl: 'https://res.cloudinary.com/dtksefjal/video/upload/v1733441305/kxdunvbfxuxgbehwaoia.mp3'
  },
  {
    id: '3',
    title: 'Wedding First Dance',
    description: 'Emotional acoustic love song',
    genre: 'Acoustic',
    audioUrl: 'https://res.cloudinary.com/dtksefjal/video/upload/v1733441501/lngavjmnlvhfhucix6rk.mp3'
  }
];