import React from 'react';
import { LucideIcon } from 'lucide-react';

interface GradientIconProps {
  icon: LucideIcon;
  gradient: string;
  size?: number;
  className?: string;
}

export function GradientIcon({ icon: Icon, gradient, size = 6, className = '' }: GradientIconProps) {
  return (
    <Icon 
      className={`h-${size} w-${size} bg-gradient-to-br ${gradient} bg-clip-text text-transparent ${className}`}
    />
  );
}