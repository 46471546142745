export const STRIPE_CONFIG = {
  PUBLIC_KEY: 'pk_test_51QSmRfGGlai4tPqnbweMPDkjyPjTR6VnoySZi0Uk9muZAEkrH2RbeCN5FEjLmYqp69M6upU4tKY2N9R8w9W9z8Wd00jUKuLQ9J',
  API_VERSION: '2023-10-16',
  SONG_PRICE: 4900, // $49.00 in cents
  SETTINGS: {
    appearance: {
      theme: 'stripe',
      variables: {
        colorPrimary: '#6366f1',
        colorBackground: '#ffffff',
        colorText: '#1f2937',
        colorDanger: '#ef4444',
        fontFamily: 'system-ui, -apple-system, sans-serif',
        spacingUnit: '4px',
        borderRadius: '8px',
      },
    },
    mode: 'payment',
    currency: 'usd',
  }
} as const;