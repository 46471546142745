import React from 'react';
import { Music2 } from 'lucide-react';
import { AudioPlayer } from './AudioPlayer';
import { useAudioPlayer } from '../hooks/useAudioPlayer';
import { samples } from '../data/samples';

export function AudioSamples() {
  const { playing, loading, error, togglePlay } = useAudioPlayer();

  return (
    <div className="bg-white py-24">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h2 className="text-4xl font-bold mb-6">Listen to Our Sample Songs</h2>
          <p className="text-xl text-gray-600">
            Here are some examples of custom songs we've created
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {samples.map((sample) => (
            <div
              key={sample.id}
              className="bg-purple-50 rounded-xl p-6 hover:shadow-lg transition-shadow duration-300"
            >
              <div className="flex items-start justify-between mb-4">
                <div>
                  <h3 className="text-xl font-semibold mb-2">{sample.title}</h3>
                  <p className="text-gray-600 text-sm mb-2">{sample.description}</p>
                  <span className="inline-block bg-purple-100 text-purple-800 text-xs px-3 py-1 rounded-full">
                    {sample.genre}
                  </span>
                </div>
                <Music2 className="h-6 w-6 text-purple-600" />
              </div>
              <AudioPlayer
                id={sample.id}
                playing={playing === sample.id}
                loading={loading && playing === sample.id}
                error={error && playing === sample.id ? error : null}
                onToggle={() => togglePlay(sample.id, sample.audioUrl)}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}