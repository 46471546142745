import React from 'react';
import { Play, Pause } from 'lucide-react';

interface AudioPlayerProps {
  id: string;
  playing: boolean;
  loading: boolean;
  error: string | null;
  onToggle: () => void;
}

export function AudioPlayer({ id, playing, loading, error, onToggle }: AudioPlayerProps) {
  return (
    <>
      {error && (
        <p className="text-red-500 text-sm mb-2">{error}</p>
      )}
      <button
        onClick={onToggle}
        disabled={loading}
        className={`w-full flex items-center justify-center bg-white border-2 border-purple-600 text-purple-600 py-3 px-4 rounded-lg hover:bg-purple-50 transition-colors duration-200 ${
          loading ? 'opacity-75 cursor-wait' : ''
        }`}
      >
        {loading ? (
          <span className="flex items-center">
            <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-purple-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
            Loading...
          </span>
        ) : playing ? (
          <>
            <Pause className="h-5 w-5 mr-2" />
            Pause Sample
          </>
        ) : (
          <>
            <Play className="h-5 w-5 mr-2" />
            Play Sample
          </>
        )}
      </button>
    </>
  );
}