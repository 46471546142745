import React from 'react';
import { LucideIcon } from 'lucide-react';
import { Card } from '../../ui/Card';
import { FeatureIcon } from './FeatureIcon';

interface FeatureCardProps {
  icon: LucideIcon;
  title: string;
  description: string;
  gradient: string;
}

export function FeatureCard({ icon, title, description, gradient }: FeatureCardProps) {
  return (
    <Card gradient={gradient} className="bg-white/95 backdrop-blur-lg shadow-xl">
      <FeatureIcon icon={icon} gradient={gradient} />
      <h3 className="text-xl font-semibold text-gray-900 mb-3">{title}</h3>
      <p className="text-gray-600 leading-relaxed">{description}</p>
    </Card>
  );
}