import React from 'react';
import { LucideIcon } from 'lucide-react';
import { Card } from '../../ui/Card';
import { GradientIcon } from '../../ui/GradientIcon';
import { UseCaseFeature } from './UseCaseFeature';

interface UseCaseCardProps {
  icon: LucideIcon;
  title: string;
  description: string;
  color: string;
  features: string[];
}

export function UseCaseCard({ icon, title, description, color, features }: UseCaseCardProps) {
  return (
    <Card className="overflow-hidden" hover={false}>
      <div className={`h-2 bg-gradient-to-r ${color} -mx-8 -mt-8 mb-8`} />
      <div className="inline-flex items-center justify-center w-14 h-14 rounded-xl bg-gray-50 mb-6 group-hover:scale-110 transition-transform duration-300">
        <GradientIcon icon={icon} gradient={color} size={7} />
      </div>
      <h3 className="text-xl font-semibold text-gray-900 mb-4">{title}</h3>
      <p className="text-gray-600 mb-6">{description}</p>
      <ul className="space-y-2">
        {features.map((feature, idx) => (
          <UseCaseFeature key={idx} feature={feature} />
        ))}
      </ul>
    </Card>
  );
}