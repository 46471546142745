import React from 'react';
import { LucideIcon } from 'lucide-react';
import { GradientIcon } from '../../ui/GradientIcon';

interface FeatureIconProps {
  icon: LucideIcon;
  gradient: string;
}

export function FeatureIcon({ icon, gradient }: FeatureIconProps) {
  return (
    <div className="inline-flex items-center justify-center w-12 h-12 rounded-xl bg-gray-50 mb-6 group-hover:scale-110 transition-transform duration-300">
      <GradientIcon icon={icon} gradient={gradient} />
    </div>
  );
}