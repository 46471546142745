import React from 'react';
import { Heart, Cake, Baby, PartyPopper } from 'lucide-react';
import { Section } from '../ui/Section';
import { UseCaseCard } from './use-case/UseCaseCard';

const cases = [
  {
    icon: Heart,
    title: 'Anniversaries',
    description: 'Celebrate your journey with a timeless melody that captures your love story',
    color: 'from-red-500 to-pink-500',
    features: ['Romantic compositions', 'Personal memories', 'Milestone celebrations']
  },
  {
    icon: Cake,
    title: 'Birthdays',
    description: 'Create an unforgettable musical gift that makes their special day extraordinary',
    color: 'from-purple-500 to-indigo-500',
    features: ['Age-appropriate themes', 'Custom lyrics', 'Celebration focus']
  },
  {
    icon: Baby,
    title: 'Family Moments',
    description: 'Transform precious memories into beautiful melodies that last generations',
    color: 'from-blue-500 to-cyan-500',
    features: ['Gentle compositions', 'Family stories', 'Legacy pieces']
  },
  {
    icon: PartyPopper,
    title: 'Special Events',
    description: 'Mark life\'s milestones with unique songs tailored to your celebration',
    color: 'from-emerald-500 to-teal-500',
    features: ['Event-specific themes', 'Customized tempo', 'Occasion-based lyrics']
  }
];

export function UseCases() {
  return (
    <Section 
      className="bg-gray-50"
      title="Crafting Perfect Melodies for Every Moment"
      subtitle="From intimate celebrations to grand occasions, we create bespoke musical experiences"
    >
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
        {cases.map((item, index) => (
          <UseCaseCard key={index} {...item} />
        ))}
      </div>
    </Section>
  );
}