import React from 'react';

interface CardProps {
  children: React.ReactNode;
  className?: string;
  gradient?: string;
  hover?: boolean;
}

export function Card({ children, className = '', gradient, hover = true }: CardProps) {
  return (
    <div 
      className={`
        relative group bg-white rounded-2xl p-8 
        ${hover ? 'hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1' : ''}
        ${className}
      `}
    >
      {gradient && (
        <div className={`absolute inset-0 bg-gradient-to-br ${gradient} opacity-0 group-hover:opacity-5 rounded-2xl transition-opacity duration-300`} />
      )}
      <div className="relative">
        {children}
      </div>
    </div>
  );
}