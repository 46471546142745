import React from 'react';
import { MessageSquare, Music, Headphones } from 'lucide-react';

const steps = [
  {
    icon: MessageSquare,
    title: 'Share Your Story',
    description: 'Fill out our simple form to tell us about the person, event, or emotion you would like to capture.'
  },
  {
    icon: Music,
    title: 'Choose Your Style',
    description: 'Pick the genre, mood, and tone that fits your vision.'
  },
  {
    icon: Headphones,
    title: 'Sit Back and Relax',
    description: 'Our team will compose, produce, and deliver your personalized song.'
  }
];

export function Process() {
  return (
    <div className="py-24">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-4xl font-bold text-center mb-16">How It Works</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
          {steps.map((step, index) => (
            <div key={index} className="text-center">
              <div className="relative">
                <step.icon className="h-16 w-16 mx-auto text-purple-600 mb-6" />
                {index < steps.length - 1 && (
                  <div className="hidden md:block absolute top-8 left-full w-full border-t-2 border-purple-200 -z-10"></div>
                )}
              </div>
              <h3 className="text-xl font-semibold mb-4">{step.title}</h3>
              <p className="text-gray-600">{step.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}