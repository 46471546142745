import React from 'react';
import { Music, Play, TreePine } from 'lucide-react';

export function Hero() {
  const scrollToContact = () => {
    document.getElementById('contact')?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="relative min-h-screen bg-gradient-to-br from-indigo-900 via-purple-900 to-violet-900 text-white">
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-[url('https://images.unsplash.com/photo-1511379938547-c1f69419868d?auto=format&fit=crop&q=80')] bg-cover bg-center opacity-10"></div>
      </div>
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-32 pb-24">
        <div className="text-center">
          <div className="inline-flex items-center justify-center p-3 bg-white/10 backdrop-blur-sm rounded-full mb-8">
            <TreePine className="h-8 w-8 text-green-400 mr-2" />
            <Music className="h-8 w-8" />
          </div>
          <h1 className="text-6xl font-bold tracking-tight mb-6 bg-clip-text text-transparent bg-gradient-to-r from-purple-400 to-pink-300">
            Your Story, Your Holiday Song
          </h1>
          <p className="text-2xl text-purple-100 max-w-2xl mx-auto mb-12 leading-relaxed">
            Create magical holiday memories with custom songs that capture the spirit of the season. From festive celebrations to heartwarming moments, we turn your winter wishes into melodies.
          </p>
          <div className="flex flex-col sm:flex-row items-center justify-center gap-4">
            <button 
              onClick={scrollToContact}
              className="group flex items-center bg-white text-purple-900 px-8 py-4 rounded-full font-semibold text-lg hover:bg-purple-100 transition-all duration-200"
            >
              <Play className="h-5 w-5 mr-2 group-hover:animate-pulse" />
              Start Your Holiday Song
            </button>
            <button 
              onClick={() => document.getElementById('samples')?.scrollIntoView({ behavior: 'smooth' })}
              className="px-8 py-4 rounded-full font-semibold text-lg border-2 border-white/20 hover:bg-white/10 transition-all duration-200"
            >
              Listen to Samples
            </button>
          </div>
        </div>
      </div>
      <div className="absolute bottom-0 left-0 right-0">
        <svg className="w-full h-24 fill-gray-100" viewBox="0 0 1440 74" preserveAspectRatio="none">
          <path d="M0,0 C480,74 960,74 1440,0 L1440,74 L0,74 Z" />
        </svg>
      </div>
    </div>
  );
}