import React from 'react';
import { Star } from 'lucide-react';

interface UseCaseFeatureProps {
  feature: string;
}

export function UseCaseFeature({ feature }: UseCaseFeatureProps) {
  return (
    <li className="flex items-center text-sm text-gray-500">
      <Star className="h-4 w-4 mr-2 text-gray-400" />
      {feature}
    </li>
  );
}