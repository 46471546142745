import { loadStripe } from '@stripe/stripe-js';
import { useState } from 'react';
import { STRIPE_CONFIG } from '../config/stripe';
import { apiService } from '../lib/api';

const stripePromise = loadStripe(STRIPE_CONFIG.PUBLIC_KEY);

export function usePayment() {
  const [paymentStatus, setPaymentStatus] = useState({
    loading: false,
    error: null as string | null,
    success: false,
  });

  const handlePayment = async () => {
    setPaymentStatus({ loading: true, error: null, success: false });
    
    try {
      const stripe = await stripePromise;
      if (!stripe) {
        throw new Error('Failed to initialize payment system');
      }

      // Create payment intent through our backend
      const { clientSecret } = await apiService.createPaymentIntent();

      // Confirm the payment directly without Elements
      const { error: paymentError, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: {
            token: 'tok_visa' // Test token for demo purposes
          },
        },
      });

      if (paymentError) {
        throw new Error(paymentError.message);
      }

      if (paymentIntent.status === 'succeeded') {
        setPaymentStatus({ loading: false, error: null, success: true });
        return true;
      } else {
        throw new Error('Payment failed');
      }

    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'Payment processing failed';
      setPaymentStatus({
        loading: false,
        error: errorMessage,
        success: false,
      });
      return false;
    }
  };

  return { paymentStatus, handlePayment };
}