import axios from 'axios';
import { API_CONFIG } from '../config/api';

const api = axios.create({
  baseURL: API_CONFIG.baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const apiService = {
  async createPaymentIntent() {
    try {
      const response = await api.post('/api/payments/create-payment-intent');
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const errorMessage = error.response?.data?.error?.message || 'Payment request failed';
        console.error('Payment API Error:', errorMessage);
        throw new Error(errorMessage);
      }
      throw error;
    }
  },
};