import React from 'react';
import { Music, Star, Clock, Gift } from 'lucide-react';
import { CountdownTimer } from './holiday/CountdownTimer';

const features = [
  {
    icon: Music,
    text: 'Professional studio recording'
  },
  {
    icon: Star,
    text: 'Personalized holiday lyrics and melody'
  },
  {
    icon: Clock,
    text: 'Delivery before Christmas'
  },
  {
    icon: Gift,
    text: 'Free holiday gift wrapping'
  }
];

export function Pricing() {
  const scrollToContact = () => {
    document.getElementById('contact')?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="bg-gradient-to-b from-gray-100 to-red-50 py-24">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h2 className="text-4xl font-bold mb-4 text-red-900">Holiday Special Offers</h2>
          <p className="text-xl text-gray-600">
            Give the gift of music this holiday season
          </p>
          <div className="mt-8">
            <CountdownTimer />
          </div>
        </div>
        <div className="max-w-lg mx-auto">
          <div className="bg-white rounded-2xl shadow-lg overflow-hidden hover:shadow-xl transition-shadow duration-200 border-2 border-red-100">
            <div className="relative overflow-hidden">
              <div className="absolute top-5 -right-12 bg-green-600 text-white px-12 py-1 transform rotate-45">
                Holiday Sale
              </div>
              <div className="p-8">
                <div className="text-center">
                  <h3 className="text-2xl font-bold mb-4">Holiday Song Package</h3>
                  <div className="text-5xl font-bold mb-2">
                    <span className="text-2xl line-through text-gray-400">$69</span>
                    <span className="ml-2">$49</span>
                    <span className="text-lg font-normal text-gray-600">/song</span>
                  </div>
                  <p className="text-green-600 font-semibold mb-6">Save $20 this season!</p>
                </div>
                <div className="space-y-6 mb-8">
                  {features.map((feature, index) => (
                    <div key={index} className="flex items-center">
                      <feature.icon className="h-6 w-6 text-red-600 mr-3" />
                      <span className="text-gray-700">{feature.text}</span>
                    </div>
                  ))}
                </div>
                <div className="bg-red-50 p-4 rounded-lg mb-8">
                  <p className="text-red-700 text-sm text-center">
                    Order now to ensure delivery before Christmas! 🎄
                  </p>
                </div>
                <button 
                  onClick={scrollToContact}
                  className="w-full bg-gradient-to-r from-red-600 to-green-600 text-white py-4 rounded-lg hover:from-red-700 hover:to-green-700 transition-all duration-200 font-semibold shadow-lg"
                >
                  Create Your Holiday Song
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}