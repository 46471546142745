import React, { useState } from 'react';
import { useContactForm } from '../hooks/useContactForm';
import { usePayment } from '../hooks/usePayment';
import { ContactFormData } from '../types/form';
import { CreditCard } from 'lucide-react';
import { PaymentButton } from './PaymentButton';
import toast, { Toaster } from 'react-hot-toast';

export function Contact() {
  const [formData, setFormData] = useState<ContactFormData>({
    name: '',
    email: '',
    message: '',
  });

  const { status, sendEmail } = useContactForm();
  const { paymentStatus, handlePayment } = usePayment();

  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    
    if (!formData.name || !formData.email || !formData.message) {
      toast.error('Please fill in all fields');
      return;
    }

    try {
      const paymentResult = await handlePayment();
      
      if (!paymentResult) {
        toast.error(paymentStatus.error || 'Payment failed. Please try again.');
        return;
      }

      const emailResult = await sendEmail(formData);
      
      if (emailResult) {
        toast.success('Demo: Payment successful and message sent!');
        setFormData({ name: '', email: '', message: '' });
      } else {
        toast.error(status.error || 'Failed to send message');
      }
    } catch (err) {
      toast.error('An unexpected error occurred. Please try again.');
      console.error('Form submission error:', err);
    }
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const isLoading = status.loading || paymentStatus.loading;
  const isFormValid = formData.name && formData.email && formData.message;

  return (
    <div className="bg-purple-700 text-white py-24">
      <Toaster position="top-center" />
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h2 className="text-4xl font-bold mb-4">Let's Start Writing Your Song</h2>
          <p className="text-xl text-purple-100">
            Have questions? Want to brainstorm ideas? We're here to help.
          </p>
          <div className="mt-6 inline-flex items-center bg-purple-800 px-4 py-2 rounded-lg">
            <CreditCard className="h-5 w-5 mr-2" />
            <span>$49 per custom song</span>
          </div>
        </div>
        <div className="max-w-2xl mx-auto">
          <form className="space-y-6" onSubmit={(e) => e.preventDefault()}>
            <div>
              <label htmlFor="name" className="block text-sm font-medium mb-2">
                Name
              </label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
                disabled={isLoading}
                className="w-full px-4 py-3 rounded-lg bg-purple-600 text-white placeholder-purple-300 border border-purple-500 focus:outline-none focus:ring-2 focus:ring-purple-400 disabled:opacity-50"
                placeholder="Your name"
              />
            </div>
            <div>
              <label htmlFor="email" className="block text-sm font-medium mb-2">
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                disabled={isLoading}
                className="w-full px-4 py-3 rounded-lg bg-purple-600 text-white placeholder-purple-300 border border-purple-500 focus:outline-none focus:ring-2 focus:ring-purple-400 disabled:opacity-50"
                placeholder="your@email.com"
              />
            </div>
            <div>
              <label htmlFor="message" className="block text-sm font-medium mb-2">
                Your Song Idea
              </label>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                required
                disabled={isLoading}
                rows={4}
                className="w-full px-4 py-3 rounded-lg bg-purple-600 text-white placeholder-purple-300 border border-purple-500 focus:outline-none focus:ring-2 focus:ring-purple-400 disabled:opacity-50"
                placeholder="Tell us about your song idea..."
              ></textarea>
            </div>
            <PaymentButton
              isLoading={isLoading}
              disabled={!isFormValid}
              onClick={handleSubmit}
            />
          </form>
        </div>
      </div>
    </div>
  );
}