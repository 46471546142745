import React from 'react';

export function ChristmasLights() {
  return (
    <div className="fixed top-0 left-0 right-0 h-6 z-50 flex justify-between px-2 overflow-hidden pointer-events-none">
      {Array.from({ length: 20 }).map((_, i) => (
        <div
          key={i}
          className={`w-4 h-4 rounded-full ${
            i % 4 === 0 ? 'bg-red-500' :
            i % 4 === 1 ? 'bg-green-500' :
            i % 4 === 2 ? 'bg-yellow-500' :
            'bg-blue-500'
          } animate-pulse`}
          style={{
            animationDelay: `${i * 0.1}s`,
            boxShadow: '0 0 10px currentColor'
          }}
        />
      ))}
    </div>
  );
}